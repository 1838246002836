import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Footer = () => {



    return (
        <>
            <div className="footer-cta" style={{
                backgroundImage:
                    `url('/assets/footercta.png')`, backgroundSize: 'cover', backgroundPosition: 'center'
            }}>
                <h1>A BreakThrough Software Development Agency</h1>
                <div className="footer-cta-text">
                    <h3>Ready To Get Started?</h3>
                    <p>
                        Ready to build something revolutionary, improve operations or just build something cool?
                    </p>
                    <div className='chevron-buttons'>

                        <Link to="https://calendar.app.google/hSAfDHZEHgxo7hXY7" target='_blank'>Schedule Meeting          <i className='material-icons'>
                            arrow_right_alt
                        </i></Link>
                        <Link to="mailto:eldrbreak@gmail.com">Email Us
                            <i className='material-icons'>
                                arrow_right_alt
                            </i>
                        </Link>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-logo">
                            <img src="/assets/eldrbreaklogo.png" alt="logo" />
                            <a >
                                Owned Operated in the USA · Founded in Mississippi
                            </a>
                            <p>
                                Let’s grow your business, improve operations, and make something flat out cool. Eldrbreak breaks down walls to all your software needs.
                            </p>
                        </div>
                        <div className="footer-links">
                            <div className="footer-link-group">
                                <h4>Operating Hours</h4>
                                <ul>
                                    <li>
                                        <span>Mon - Sat: 8am - 5pm</span>
                                    </li>
                                    <li>
                                        <span>Sun: Closed</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-group">
                                <h4>Get Started</h4>
                                <ul>
                                    <li>
                                        <Link to="mailto:eldrbreak@gmail.com">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="https://calendar.app.google/zPL7fx4SXveAGdF36" target="_blank">Schedule Meeting</Link>
                                    </li>
                                    <li>
                                        <Link to="https://buy.stripe.com/eVadUMelt0lU0sU6oo" target="_blank">Pay Bill Online</Link>
                                    </li>
                                    {/*   <li>
                                        <Link to="/contact">Estimate Quote</Link>
                                    </li> */}
                                </ul>
                            </div>
                            {/*   <div className="footer-link-group">
                                <h4>Services</h4>
                                <ul>
                                    <li>
                                        <Link to="/commercial-industrial">Commercial & Industrial</Link>
                                    </li>
                                    <li>
                                        <Link to="/residential-home">Residential & Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/why-drones">Why Drones?</Link>
                                    </li>

                                </ul>
                            </div> */}

                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>&copy; Copyright Eldrbreak 2024. All rights reserved</p>
                        <ul>
                            <li>
                                <Link to="/stripe-policy">Fulfillment Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer