import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {

            setIsScrolled(window.scrollY > 50);


        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>

            <nav className={
                isScrolled ? 'navbar scrolled' : 'navbar'
            }>
                <img className='navbar-logo' src={'/assets/eldrbreaklogo.png'} alt="logo" />
                <ul>
                    
                    <li>
                        <Link to="https://calendar.app.google/zPL7fx4SXveAGdF36" target='_blank'>Schedule Meeting</Link>
                    </li>
                    <li>
                        <Link to="mailto:eldrbreak@gmail.com">Email Us</Link>
                    </li>
                    <li>
                        <Link to="https://buy.stripe.com/eVadUMelt0lU0sU6oo">Pay Bill Online</Link>
                    </li>
                    {/*    <li>
                        <Link to="/about">Estimate Project</Link>
                    </li> */}
                </ul>
            </nav>
        </>
    );
};

export default Navbar
