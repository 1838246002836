import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './publicPages/landingPage';
import loadable from '@loadable/component';
import Page404 from './publicPages/404';
import { useSelector } from 'react-redux';
import FulfillmentPolicy from './publicPages/fullfillment';


const LoadableLandingPage = loadable(() => import('./publicPages/lodable'))

/* 
   Loadable components help split the react app into smaller chunks, good for ssr
*/

/* 
    Mainapp is for a dashboard
    Public pages are for the landing pages of a website
*/


const App = () => {


    useEffect(() => {
        if (process.env.NODE_COMPILE != 'split' && (!window.location.href.includes('localhost'))) {
            const clarityScript = document.createElement("script");
            clarityScript.type = "text/javascript";
            clarityScript.setAttribute("data-prod-only", "true");

            clarityScript.text = `(function (c, l, a, r, i, t, y) {
                 c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                 t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                 y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
             })(window, document, "clarity", "script", "jcrumyfjwl");`;

            document.head.appendChild(clarityScript);
        }
    }, [])

    return (
        <>
            <Routes>
                <Route>
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/stripe-policy' element={<FulfillmentPolicy />} />

                    <Route path="*" element={<Page404 />} />
                </Route>


            </Routes>
        </>


    )
};

export default App;
