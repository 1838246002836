import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './components/navbar';
import { useState } from 'react';
import { Accordion } from '@mantine/core';
import classes from '../css/item.module.css';
import Footer from './components/footer';

const LandingPage = () => {

    const groceries = [
        {
            value: 'What type of software development do we specialize in?',
            description:
                'We specialize in web, mobile, and desktop software development. We use industry-leading technologies to build scalable, high-performance software solutions.',
        },
        {
            value: 'Where is our team located?',
            description:
                'Our team is based is based in South Mississippi, but we work with clients nationwide. We are happy to work with you no matter where you are located.',
        },
        {
            value: 'What does the process look like?',
            description:
                'We start by understanding your business needs and goals. Then we develop a strategy to meet those goals. We work with you every step of the way to ensure your satisfaction. We start with a high resolution mockup of your project, then we work with you and our professional team of developers to build the solution of your dreams.',
        },
        {
            value: 'How do I schedule an appointment for your services?',
            description: "Please feel free to Email or Book a consultation with us on our website. We will get back to you as soon as possible.",
        },

    ];

    const [openedItem, setOpenedItem] = useState(null);

    const handleAccordionChange = (value) => {
        if (value !== openedItem) {

            setOpenedItem(value);
        }
    };


    const items = groceries.map((item) => (
        <Accordion.Item key={item.value} value={item.value}>
            <Accordion.Control>{item.value}</Accordion.Control>
            <Accordion.Panel>
                <p style={{ padding: '0px 13px 13px 13px', maxWidth: '1000px' }}>
                    {item.description}
                </p>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <>

            <Navbar />
            <section className='hero-banner'>
                <img className='hero-banner-image' src={'/assets/herobanner.png'} alt="logo" />
                <div className='hero-banner-text'>
                    <span>Software Development Agency</span>
                    <h1>Let's Build <span>Ground Breaking</span> Tech Together</h1>
                    <p>
                        Let’s grow your business, improve operations, and make something flat out cool. Eldrbreak breaks down walls to all your software needs.
                    </p>
                    <div className='chevron-buttons'>

                        <Link to="https://calendar.app.google/hSAfDHZEHgxo7hXY7" target='_blank'>Schedule Meeting          <i className='material-icons'>
                            arrow_right_alt
                        </i></Link>
                        <Link to="mailto:eldrbreak@gmail.com">Email Us
                            <i className='material-icons'>
                                arrow_right_alt
                            </i>
                        </Link>
                    </div>
                </div>
                <div className='hero-badge-container'>
                    <img className='hero-badge' src='/assets/ai-award.png' />
                </div>
            </section>

            <div className='landing-page-sections'>
                <section className='block-card-container'>
                    <div className='section-header'>
                        <h2>We have a process that allows us to deliver breakthrough technologies </h2>
                        <p>Following careful examination of your business needs, our expert team will recommend the best strategy to successfully achieve your goals.</p>
                    </div>

                    <div className='block-cards'>
                        <div className='block-card'>
                            <img src='/assets/business.png' />
                            <h3>We Want To Know Your <span>Business</span></h3>
                            <div className='block-card-text'>
                                <p>Talk our heads OFF. We love hearing from businesses that are ready to succeed.</p>
                                <span>1</span>
                            </div>
                        </div>
                        <div className='block-card'>
                            <img src='/assets/code.png' />
                            <h3>Beautiful <span>Design</span> & Powerful <span>Code</span></h3>
                            <div className='block-card-text'>
                                <p>We use industry standard web, native, and hybrid software development techniques.</p>
                                <span>2</span>
                            </div>
                        </div>
                        <div className='block-card'>
                            <img src='/assets/launch.png' />
                            <h3>Launch & Grow Your <span>Business</span></h3>
                            <div className='block-card-text'>
                                <p>Deploy your solution, scale your business, and thrive with our ongoing support and insights.</p>
                                <span>3</span>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

            <section className='specialty-section'>
                <div className='landing-page-sections'>
                    <div className='section-header inverted-color'>
                        <h2>We have many specialties check out some products <br /> <span>built by Eldrbreak</span></h2>
                        <p>Each product we craft is designed with scalability, performance, and your vision in mind. At Eldrbreak, we don’t just meet expectations—we redefine them.</p>
                    </div>

                    <div className='zigzag-container'>

                        <div className='zigzag-item'>
                            <div className='img-zig' style={{
                                backgroundImage:
                                    `url('/assets/schoolsense.png')`
                            }}>

                                <div className='zigzag-text'>
                                    <span>School Fundraising Platform</span>
                                    <h3>SchoolSense</h3>
                                    <p>
                                        Over <span>$50,000</span> Raised in the first month for class of 2025
                                    </p>
                                    <div className='chevron-buttons'>
                                        <Link to="https://schoolsensefundraising.com/" target='_blank'>Check It Out
                                            <i className='material-icons'>
                                                arrow_right_alt
                                            </i>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                            <div className='text-zag'>
                                <div className='zigzag-text'>
                                    <h3><span>E-commerce Software</span>
                                        <br />
                                        Development</h3>
                                    <p>
                                        We build tech that scales to meet you and your <br /> customers where they are.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='zigzag-item'>
                            <div className='img-zig' style={{
                                backgroundImage:
                                    `url('/assets/foodscout.png')`
                            }}>
                                <div className='zigzag-text'>
                                    <span>All Food Delivery Apps In One</span>
                                    <h3>FoodScout</h3>
                                    <p>

                                        Over <span>300,000</span> restaurants in <span>300+</span> cities in the U.S.


                                    </p>
                                    <div className='chevron-buttons'>
                                        <Link to="https://foodscoutapp.com/" target='_blank'>FoodScout It Out
                                            <i className='material-icons'>
                                                arrow_right_alt
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='text-zag'>
                                <div className='zigzag-text'>
                                    <h3><span>Mobile App</span> <br />
                                        Development</h3>
                                    <p>
                                        We use industry leading technologies react native, web scraping,<br /> highly scalable server solutions, and more.
                                    </p>
                                </div>
                            </div>
                        </div>



                        <div className='zigzag-item'>
                            <div className='img-zig' style={{
                                backgroundImage:
                                    `url('/assets/flybyshine.png')`
                            }}>
                                <div className='zigzag-text'>
                                    <span>Drone Cleaning Service</span>
                                    <h3>FlyBy Shine</h3>
                                    <p>

                                        A revolutionary drone cleaning service.

                                    </p>
                                    <div className='chevron-buttons'>
                                        <Link to="https://flybyshine.com/" target='_blank'>Check It Out
                                            <i className='material-icons'>
                                                arrow_right_alt
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='text-zag'>
                                <div className='zigzag-text'>
                                    <h3><span>Web Design </span> <br />
                                        & Development</h3>
                                    <p>
                                        Beautiful websites that are personalized and designed for your brand (we never use templates).
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>


            </section>

            <section className='faq-section'>
                <div className='landing-page-sections'>
                    <div className='section-header'>
                        <h2>Frequently asked questions <br />about  <span>Eldrbreak</span>  </h2>
                        <p>You have questions we have answers. If you can’t find your answer here contact us.</p>
                    </div>

                    <div className='faq-accordion'>
                        <Accordion
                            classNames={{ chevron: classes.chevron }}
                            chevron={
                                <div
                                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                    className={classes.icon}
                                >
                                    <i style={{ fontSize: '33px', color: 'black' }} className='material-icons-round'>add</i>
                                </div>
                            }
                            variant="contained"
                            onChange={handleAccordionChange}
                        >
                            {items}
                        </Accordion>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default LandingPage
