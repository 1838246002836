import React from "react";
import Navbar from "./components/navbar";
const FulfillmentPolicy = () => {
    return (

        <>

            <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6", paddingTop: 50, margin: 'auto', maxWidth: 1000 }}>
                <h1 style={{ marginBottom: 10 }}>Fulfillment Policy</h1>

                <section>
                    <h2>Refund Policy</h2>
                    <p>
                        Our goal is to deliver high-quality software development services that meet your expectations.
                        Refunds are available under the following conditions:
                    </p>
                    <ul>
                        <li>
                            If the project requirements agreed upon in the contract are not met, you may request a partial or
                            full refund, depending on the circumstances and completed work.
                        </li>
                        <li>
                            Refund requests must be made in writing within 7 days of the project milestone delivery.
                        </li>
                        <li>
                            Refunds will not be provided for completed and approved milestones unless specified in the
                            contract.
                        </li>
                        <li>
                            Custom work, such as software code and deliverables, is non-refundable once approved or deployed.
                        </li>
                    </ul>
                    <p>
                        Each refund request will be reviewed on a case-by-case basis in line with the agreed terms.
                    </p>

                    <h2>Project Delivery Policy</h2>
                    <p>
                        We are committed to delivering projects in accordance with the timeline and scope agreed upon in the
                        contract. Our delivery process includes:
                    </p>
                    <ul>
                        <li>
                            Regular updates at defined milestones or intervals as specified in the project agreement.
                        </li>
                        <li>
                            Delivery of software and assets via secure file transfer or deployment to specified platforms upon
                            completion.
                        </li>
                        <li>
                            All deliverables will be tested and reviewed before handover.
                        </li>
                        <li>
                            Any delays will be communicated promptly, along with revised timelines and necessary adjustments.
                        </li>
                    </ul>
                    <h2>Cancellation Policy</h2>
                    <p>
                        Either party may cancel the project under the terms outlined in the contract. The following conditions apply:
                    </p>
                    <ul>
                        <li>
                            Cancellation requests must be submitted in writing and will take effect upon acknowledgment.
                        </li>
                        <li>
                            For work completed up to the cancellation date, payment is required for approved milestones.
                        </li>
                        <li>
                            Deposits or upfront payments are non-refundable unless otherwise stated in the contract.
                        </li>
                    </ul>
                    <p>
                        In the event of cancellation, any partially completed work will be provided in its current state, and
                        all associated intellectual property rights will be governed by the agreement terms.
                    </p>
                    <h2>Return Policy</h2>
                    <p>
                        Due to the nature of software development services, returns are not applicable. However, we stand by
                        the quality of our work and are happy to address any concerns or issues through revisions or
                        additional support, as outlined in the contract.
                    </p>
                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our fulfillment policies, please contact us at:
                    </p>
                    <p>Email: <a href="mailto:eldrbreak@gmail.com">eldrbreak@gmail.com</a></p>

                </section>


            </div>
        </>
    );
};

export default FulfillmentPolicy;
